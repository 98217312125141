<template>
  <div class="calendar" v-if="!hideCalendar">
    <div class="date-time">
      <span class="date-time__label">DATE</span>
      <span class="date-time__date">{{ readableDate }}</span>
      <span class="date-time__time-interval">{{ readableTime }}</span>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    startDate: {
      type: [String, Date],
      default: "",
    },
    endDate: {
      type: [String, Date],
      default: "",
    },
  },

  computed: {
    isEndingToday() {
      return moment(this.endDate).isSame(new Date(), "day");
    },
    readableDate() {
      return moment(this.startDate).format("MMMM DD, YYYY");
    },
    readableTime() {
      if (!this.startDate && !this.endDate) {
        return "0:00 AM - 0:00 AM";
      }
      const startTime = moment(this.startDate).format("h:mm A");
      const endTime = moment(this.endDate).format("h:mm A");
      return `${startTime} - ${endTime}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";
.calendar {
  margin-top: 24px;
  .date-time {
    border: 2px solid #ffffff33;
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    min-height: 120px;
    padding: 16px;

    span {
      display: block;
    }

    &__label {
      font-family: $Oswald;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.05em;
      color: #ffffff80;
      margin-bottom: 16px;
    }

    &__date {
      font-family: $Oswald;
      font-weight: 600;
      font-size: 24px;
      line-height: 40px;
      letter-spacing: 0.05em;
      width: 200px;
      margin-bottom: 12px;
      text-transform: uppercase;
    }

    &__time-interval {
      font-family: $Lato;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.05em;
      color: #ffffff80;
    }
  }

  @media (min-width: $media-sm) {
    margin-top: 24px;
    .date-time {
      border: 2px solid #ffffff33;
      box-sizing: border-box;
      border-radius: 10px;
      width: 456px;
      min-height: 204px;
      padding: 24px;

      span {
        display: block;
      }

      &__label {
        font-family: $Oswald;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.05em;
        color: #ffffff80;
        margin-bottom: 16px;
      }

      &__date {
        font-family: $Oswald;
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 0.05em;
        width: 200px;
        margin-bottom: 12px;
        text-transform: uppercase;
      }

      &__time-interval {
        font-family: $Lato;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.05em;
        color: #ffffff80;
      }
    }
  }
}
</style>
